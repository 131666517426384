import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SovosButton from 'mosaic-react/sovos-button';
import Popover from '@mui/material/Popover';
import TinMask from 'shared/dataModifier/tinMask/TinMask';
import Typeahead from 'shared/typeahead/Typeahead';
import AuthenticationHelper from 'helpers/authenticationHelper';
import { the1042SFchar } from 'helpers/formConstants';
import { debounce } from 'debounce';
import CircularProgress from '@mui/material/CircularProgress';
import '../formComponents.scss';
import './recipientInformation.scss';

class RecipientInformation extends PureComponent {
  state = {
    recipientSearch: '',
    showSearch: false,
    anchorEl: null,
    isLoading: false
  };

  componentDidMount() {
    this.handleSearchCall = debounce(this.handleSearchCall, 500);
    if (this.props.recipientID) {
      this.props.actions.fetchRecipient(this.props.recipientID, this.getRecipientType());
    }
  }

  componentDidUpdate(prevProps) {
    const { recipientID, fetchingRecipient, taxEntityCode, formActions, recipientInformation } = this.props;
    if (recipientID > 0 && recipientID !== prevProps.recipientID) {
      this.props.actions.fetchRecipient(recipientID, this.getRecipientType());
    }

    if (prevProps.fetchingRecipient && !fetchingRecipient && taxEntityCode === the1042SFchar) {
      formActions.setRecipientPayer({
        'FK1': recipientInformation.AccountNo,
        'FK2': recipientInformation.Tin,
        'MODRECNO': recipientInformation.ModRecno
      }, false);
    }
  }

  componentWillUnmount() {
    this.handleSearchCall.clear();
  }

  getRecipientType = () => this.props.taxEntityCode === the1042SFchar ? 'npayees' : 'payees';

  handleOnChangeRecipient = value => {
    this.setState({ recipientSearch: value }, () => this.handleSearchCall());
  };

  handleSearchCall = async () => {
    if (this.state.recipientSearch.value && this.state.recipientSearch.value.length >= 3) {
      this.setState({ isLoading: true });
      await this.props.actions.fetchRecipientByRegex(this.state.recipientSearch.value, this.getRecipientType());
      this.setState({ isLoading: false });
    }
  };

  renderSuggestion = suggestion => {
    if (suggestion.Name === 'notFound') {
      return (
        <div>
          <div className='suggestion-majorDetails'>
            No matching recipient found
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className='suggestion-majorDetails'>
            { suggestion.Name }
          </div>
          <div className='suggestion-minorDetails'>
            <strong>TIN: </strong>{ suggestion.Tin }
          </div>
          <div className='suggestion-minorDetails'>
            <strong>Account: </strong>{ suggestion.AccountNo }
          </div>
          {
            !AuthenticationHelper.isTIRCorporate() &&
            <div className='suggestion-minorDetails'>
              <strong>GML: </strong>{ suggestion.Gml }
            </div>
          }
        </div>
      );
    }
  };

  toggleSearch = () => this.setState({ showSearch: !this.state.showSearch });

  handleRecipientClick = event => {
    event.preventDefault();
    this.setState({
      anchorEl: event.currentTarget
    }, () => {
      this.toggleSearch();
      window.setTimeout(function () {
        document.getElementById('react-autowhatever-selectRecipient').previousSibling.focus();
      }, 0);
    });
  };

  handleRecipientSelect = selection => {
    this.toggleSearch();
    this.props.actions.setRecipient(selection);
    this.props.taxEntityCode === the1042SFchar
      ? this.props.formActions.setRecipientPayer(
        {
          'FK2': selection['Tin'],
          'FK1': selection['AccountNo'],
          'MODRECNO': selection['ModRecno']
        }, false
      )
      : this.props.formActions.setRecipientPayer(
        {
          'FK2': selection['AccountNo'],
          'FK1': selection['Tin'],
          'MODRECNO': selection['ModRecno']
        }, false
      );
  };

  render() {
    const {
      recipientInformation: { Tin, TinType, Name, Name2, Address, Address2, City, State, Zip, AccountNo },
      readOnlyUser,
      recipients,
      taxEntityCode,
      hideChangeButton
    } = this.props;
    const { showSearch, anchorEl, isLoading } = this.state;

    return (
      <div className='recipient-information__container'>
        <div className='formComponents-information__container-top'>
          <h3 className='formComponents-information__title'>Recipient information</h3>
          {
            !readOnlyUser && !hideChangeButton &&
            <div className='formComponents-information__container-button'>
              <SovosButton
                variant='text'
                onClick={ this.handleRecipientClick }
              >
                { Tin ? 'Change' : 'Add' }
              </SovosButton>
              <Popover
                className='recipient__popover'
                open={ showSearch }
                anchorEl={ anchorEl }
                anchorOrigin={ { horizontal: 'left', vertical: 'bottom' } }
                onClose={ this.toggleSearch }
                style={ { marginTop: 10, height: 100 } }
              >
                <Typeahead
                  id='selectRecipient'
                  placeholder='Name, TIN or Account'
                  data={ recipients }
                  keys={ ['Tin', 'Name', 'AccountNo'] }
                  onSuggestionSelected={ this.handleRecipientSelect }
                  renderSuggestion={ this.renderSuggestion }
                  customFetchRequested={ this.handleOnChangeRecipient }
                />
                { isLoading && <CircularProgress className='payer-recipient__search-spinner'/> }
              </Popover>
            </div>
          }
        </div>
        {
          Name &&
            (<div className='formComponents-information__container-bottom'>
              {
                taxEntityCode === the1042SFchar
                  ? (
                    <p>
                      <label>ACCOUNT: </label>
                      <span>{ AccountNo }</span>
                    </p>
                  )
                  : (
                    <p>
                      <label>TIN: </label>
                      <TinMask records={ { TIN: Tin, TINTYPE: TinType } } />
                    </p>
                  )
              }
              <p>{ Name } </p>
              { Name2 && <p>{ Name2 }</p> }
              <p>{ Address } </p>
              { Address2 && <p>{ Address2 }</p> }
              <p>{ City }, { State } { Zip } </p>
            </div>)
        }
      </div>
    );
  }
}

RecipientInformation.propTypes = {
  recipients: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    fetchRecipient: PropTypes.func.isRequired,
    setRecipient: PropTypes.func.isRequired,
    fetchRecipientByRegex: PropTypes.func.isRequired
  }).isRequired,
  formActions: PropTypes.shape({
    setRecipientPayer: PropTypes.func.isRequired
  }).isRequired,
  recipientID: PropTypes.number,
  recipientInformation: PropTypes.object.isRequired,
  fetchingRecipient: PropTypes.bool.isRequired,
  readOnlyUser: PropTypes.bool.isRequired,
  hideChangeButton: PropTypes.bool,
  taxEntityCode: PropTypes.string.isRequired
};

RecipientInformation.defaultProps = {
  recipientID: 0,
  hideChangeButton: false
};

export default RecipientInformation;
