import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FormField from './FormField';
import * as actions from '../redux/DynamicFormActions';
import checkDuplicatedHelper from './checkDuplicatedHelper';

const validationConditionsMapper = (validation, formData) => {
  if (validation?.activateOn) {
    if (Array.isArray(validation.activateOn)) {
      return validation.activateOn.map(activateCondition => formData[activateCondition.field]);
    }
    return formData[validation.activateOn.field];
  }
  return null;
};

const mapStateToProps = (state, ownProps) => {
  let isInUse = false;
  if (ownProps.metadata && ownProps.metadata.validation && ownProps.metadata.validation.unique) {
    isInUse = state.dynamicForm.fieldValueIsInUseList.includes(ownProps.metadata.entityField?.toUpperCase() || ownProps.metadata.dbColumn);
  }

  const formData = state.dynamicForm.isClone
    ? state.dynamicForm.cloneForm
    : (state.dynamicForm.form[ownProps.taxEntityCode] || {});

  return {
    conditionValue: validationConditionsMapper(ownProps.metadata.validation, formData),
    fieldValue: formData[ownProps.metadata.entityField?.toUpperCase() || ownProps.metadata.dbColumn] || formData[ownProps.metadata.entityField],
    modLevel: formData['MODLEVEL'],
    previousFieldValue: state.dynamicForm.previousValueForm[ownProps.metadata.entityField?.toUpperCase() || ownProps.metadata.dbColumn],
    showAmountDifferenceField: state.dynamicForm.showAmountDifferenceField,
    resetForm: state.dynamicForm.resetForm,
    isClone: state.dynamicForm.isClone,
    formFieldErrorMessage: state.dynamicForm.formFieldErrorMessage,
    isInUse
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const checkDuplicated = checkDuplicatedHelper[ownProps.metadata.entityField?.toUpperCase() || ownProps.metadata.dbColumn] || function(){};

  return {
    actions: bindActionCreators({ ...actions, checkDuplicated }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormField);
