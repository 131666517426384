import { Navigate } from 'react-router-dom';
import authenticationHelper from 'helpers/authenticationHelper';

const getLandingPage = () => authenticationHelper.getProductLandingPage();

const ProtectedRoute = ({ children, route = null, redirectPath = getLandingPage() }) => {
  if (route && !authenticationHelper.checkConfigurationsAndPermissions(route) && !authenticationHelper.checkProduct(route.product)) {
    return <Navigate to={ redirectPath } replace />;
  }
  return children;
};

export default ProtectedRoute;
