import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SovosButton from 'mosaic-react/sovos-button';
import RefreshIndicatorLoading from 'shared/refreshIndicatorLoading/refreshIndicatorLoading';
import authenticationHelper from 'helpers/authenticationHelper';
import { get, post } from 'helpers/axios/axiosInterceptorsV1';
import pendoInitialize from './pendo.js';
import { includeZendesk } from './zendesk.js';
import { TIR_ENT_SECURITY_ADMIN, TIR_NAVIGATE_RTT_LANDING_PAGE, TIR_NAVIGATE_WF_LANDING_PAGE } from 'helpers/permissionConstants';
import { getOrgs, getUserContexts } from 'helpers/userConfigurationHelper.js';
import {
  exists as existsFromCookie,
  getContextId as getContextIdFromCookie,
  getOrgId as getOrgIdFromCookie,
  remove as removeFromCookie,
  set as setCookie
} from 'infrastructure/cookieHelper.js';
import useContextHasPayers from '../../helpers/useContextHasPayers.js';
import useFreeTrial from 'shared/freeTrialModal/useFreeTrial.js';

const TIR_PRODUCT = 'tirProduct';

const getMaxYearProdContext = contexts =>
  contexts
    .filter(context => context.name === 'PROD')
    .reduce((prev, current) => prev.taxYear > current.taxYear ? prev : current);

const PostLogin = ({ actions }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { shouldRedirect } = useContextHasPayers();

  const { fetchSubscription } = useFreeTrial();

  useEffect(() => {
    let orgId = null;
    let contextId = null;

    if (!searchParams.get('orgId') && !searchParams.get('contextId') && existsFromCookie('orgId') && existsFromCookie('contextId')) {
      orgId = getOrgIdFromCookie();
      contextId = getContextIdFromCookie();
      removeFromCookie('orgId');
      removeFromCookie('contextId');
    }

    if (searchParams.get('orgId') && searchParams.get('contextId')) {
      setCookie(`orgId=${searchParams.get('orgId')}`);
      setCookie(`contextId=${searchParams.get('contextId')}`);
      authenticationHelper.removeJwtToken();
      sessionStorage.clear();
      navigate('/');
      return;
    }

    const getRequiredData = async () => {
      const saveUserContext = async context => {
        const path = '/users/configurations';
        const payload = {
          name: 'USER_CONTEXT',
          value: context
        };
        try {
          await post(path, payload);
          sessionStorage.setItem('USER_CONTEXT', JSON.stringify({
            ...JSON.parse(sessionStorage.getItem('USER_CONTEXT')),
            selectedContext: context
          }));
        } catch (e) {
          console.log(e);
        }
      };

      const getSolution = async () => {
        const solutionPath = '/environment/solution';
        const solutionResponse = await get(solutionPath);
        sessionStorage.setItem(TIR_PRODUCT, solutionResponse.data);
      };

      const getSavedUserContext = async () => {
        try {
          const savedContext = await get('/users/configurations/USER_CONTEXT');
          const userContext = savedContext.data.data.Value;
          userContext.taxYear = String(userContext.taxYear);
          return userContext;
        } catch (e) {
          return {};
        }
      };

      try {
        await getSolution();
        const contextInfoResponse = await actions.fetchContextInfo();
        const allOrgs = await getOrgs(orgId);
        const savedContext = await getSavedUserContext();
        const isContextSaved = Boolean(Object.keys(savedContext).length);
        const userContexts = getUserContexts(allOrgs);
        let selectedContext = {};

        if (contextId) {
          selectedContext = userContexts.find(context => context.id === String(contextId));
        } else {
          selectedContext = isContextSaved ? savedContext : getMaxYearProdContext(userContexts);
        }

        selectedContext = { ...selectedContext, taxYear: String(selectedContext.taxYear) };
        !isContextSaved && saveUserContext(selectedContext);
        actions.setOrganizationsTree(allOrgs);
        actions.fetchContextsSuccess({ allOrgs, userContexts, selectedContext });
        await actions.fetchSolutionConfigurations();
        await actions.fetchClient();

        if (authenticationHelper.isTIRCorporateECommerce()) {
          await fetchSubscription();
        }

        if (!authenticationHelper.checkSomeUserPermissions(TIR_NAVIGATE_RTT_LANDING_PAGE)) {
          actions.fetchLatestNotification();
          actions.fetchForms();
        }
        const { userId, firstName, lastName, emailAddress } = contextInfoResponse;
        setUserInfo({ userId, firstName, lastName, emailAddress });
      } catch (e) {
        console.log(e);
        //the settimeout is to avoid a race condition between window.open scenario
        setTimeout(() => {
          setOpenDialog(true);
        }, 2000);
      }
    };

    if (!searchParams.get('orgId') && !searchParams.get('contextId')) {
      getRequiredData();
    }
  }, []);

  const checkPayers = async () => {
    try {
      const response = await shouldRedirect();
      return response;
    } catch (error) {
      console.log(error);
      //the settimeout is to avoid a race condition between window.open scenario
      setTimeout(() => {
        setOpenDialog(true);
      }, 2000);
    }
  };

  const redirect = async () => {
    if (authenticationHelper.checkAllUserPermissions(TIR_ENT_SECURITY_ADMIN)) {
      navigate('/settings');
    } else if (authenticationHelper.checkAllUserPermissions(TIR_NAVIGATE_RTT_LANDING_PAGE)) {
      navigate('/realtimetin');
    } else if (authenticationHelper.checkAllUserPermissions(TIR_NAVIGATE_WF_LANDING_PAGE)) {
      if (authenticationHelper.isTIRCorporate()) {
        const redirectToNewPayerPage = await checkPayers();
        redirectToNewPayerPage ? navigate('/new-payer') : navigate('/payers');
      } else {
        navigate('/data/records');
      }
    } else {
      const redirectToNewPayerPage = await checkPayers();
      redirectToNewPayerPage ? navigate('/new-payer') : navigate(authenticationHelper.getProductLandingPage());
    }
  };

  useEffect(() => {
    const thirdParty = async pendoInfo => {
      pendoInitialize(pendoInfo);
      try {
        const response = await post('environment/tokens/helpdesk');
        includeZendesk(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (userInfo?.userId) {
      if (process.env.NODE_ENV === 'production') {
        const pendoInfo = {
          //TODO: check how we will handle this in case of sovos identity, maybe we already have this info
          userId: userInfo.userId,
          domain: userInfo.emailAddress.split('@')[1],
          clientId: authenticationHelper.getClientId(),
          taxYear: authenticationHelper.getTaxYear(),
          product: authenticationHelper.getPendoShortProductName(),
          accountName: sessionStorage.getItem('CLIENT_INFO').Name
        };
        thirdParty(pendoInfo);
      }

      redirect();
    }
  }, [userInfo]);

  return (
    <div className="otp-login">
      <div>
        <RefreshIndicatorLoading
          style={ { backgroundColor: 'transparent' } }
        />
      </div>
      <Dialog open={ openDialog } className="otp-login__error">
        <DialogTitle>
          Error
        </DialogTitle>
        <DialogContent>
          <p>There was an error loading { authenticationHelper.getProductName() }.  Please try logging in again. </p>
        </DialogContent>
        <DialogActions>
          <SovosButton
            key='continue-button'
            onClick={ () => navigate('/logout') }
          >
            Return to login
          </SovosButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

PostLogin.propTypes = {
  actions: PropTypes.shape({
    fetchContextInfo: PropTypes.func,
    fetchClient: PropTypes.func,
    fetchLatestNotification: PropTypes.func,
    fetchForms: PropTypes.func,
    setOrganizationsTree: PropTypes.func
  }).isRequired
};

export default PostLogin;