import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  STARTING_IMPORT: 'STARTING_IMPORT',
  START_IMPORT_SUCCESS: 'START_IMPORT_SUCCESS',
  START_IMPORT_FAILURE: 'START_IMPORT_FAILURE',
  CANCELLING_IMPORT: 'CANCELLING_IMPORT',
  CANCEL_IMPORT_SUCCESS: 'CANCEL_IMPORT_SUCCESS',
  CANCEL_IMPORT_FAILURE: 'CANCEL_IMPORT_FAILURE'
};

export default actionTypes;
