import authenticationHelper from 'helpers/authenticationHelper';
import { get, post } from 'helpers/axios/axiosInterceptorsV1';

const useFreeTrial = onError => {
  const fetchSubscription = async () => {
    try {
      const response = await get(`/ecommerce/client/${authenticationHelper.getClientId()}/subscription`);
      const { subscriptionStartDate } = response.data;

      const freeTrialEndDate = new Date(subscriptionStartDate);
      const currentDate = new Date();
      authenticationHelper.storeIsFreeTrial(freeTrialEndDate > currentDate);
    } catch (error) {
      onError && onError(error);
    }
  };

  const updateSubscriptionDate = async () => {
    try {
      await post(`/ecommerce/client/${authenticationHelper.getClientId()}/subscription/start-subscription`);
      authenticationHelper.storeIsFreeTrial(false);
    } catch (error) {
      onError && onError(error);
    }
  };

  return {
    fetchSubscription,
    updateSubscriptionDate
  };
};

export default useFreeTrial;
