import sharedConstants from 'redux/constants/SharedConstants';
import printConstants from '../../printPage/printSteps/PrintStepsConstants';
import jobOperationsConstants from '../jobOperations/JobOperationsConstants';

const actionTypes = {
  ...sharedConstants,
  ...printConstants,
  ...jobOperationsConstants,
  SET_PT_JOB_TYPE: 'SET_PT_JOB_TYPE',
  OPEN_PT_SLIDING_PANEL: 'PTJOB_OPEN_PT_SLIDING_PANEL',
  CLOSE_PT_SLIDING_PANEL: 'PTJOB_CLOSE_PT_SLIDING_PANEL',
  SET_FORM_SELECTED: 'PTJOB_SET_FORM_SELECTED',
  SET_PAYERS_SELECTED: 'PTJOB_SET_PAYERS_SELECTED',
  SET_PRINT_NOTES: 'PTJOB_SET_PRINT_NOTES',
  SET_FORMS_WITH_MASK: 'PTJOB_SET_FORMS_WITH_MASK',
  TOGGLE_OPTION_SELECTION: 'PTJOB_TOGGLE_OPTION_SELECTION',
  SET_OPTION_SELECTION: 'PTJOB_SET_OPTION_SELECTION',
  SET_JOB_STATUS: 'SET_JOB_STATUS',
  SET_CONFIG_TRANSMIT: 'SET_CONFIG_TRANSMIT',
  SET_CONTROLTOTALS_REFRESHABLE: 'SET_CONTROLTOTALS_REFRESHABLE',
  SET_PRINT_RESULTS: 'SET_PRINT_RESULTS',
  SET_REPRINT_OPTIONS: 'SET_REPRINT_OPTIONS'
};

export default actionTypes;
