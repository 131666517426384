import PropTypes from 'prop-types';
import SovosDialog from 'mosaic-react/sovos-dialog';
import SovosDialogActions from 'mosaic-react/sovos-dialog-actions';
import SovosDialogContent from 'mosaic-react/sovos-dialog-content';
import SovosDialogTitle from 'mosaic-react/sovos-dialog-title';
import ConfirmationDialogButtons from './ConfirmationDialogButtons';

const ConfirmationDialog = ({
  open,
  title,
  content,
  width,
  PaperProps,
  onCancelClick,
  onOKClick,
  customActions,
  customContentStyle,
  okButtonProps,
  cancelButtonProps,
  ...buttonProps
}) => (
  <SovosDialog
    open={ open }
    width={ width }
    PaperProps={ PaperProps }
  >
    { title &&
      <SovosDialogTitle>{ title }</SovosDialogTitle>
    }
    <SovosDialogContent className={ customContentStyle }>{ content }</SovosDialogContent>
    <SovosDialogActions>
      {
        customActions
          ? customActions
          : (
            <ConfirmationDialogButtons
              onCancelClick={ onCancelClick }
              onOKClick={ onOKClick }
              okButtonProps = { okButtonProps }
              cancelButtonProps = { cancelButtonProps }
              { ...buttonProps }
            />
          )
      }
    </SovosDialogActions>
  </SovosDialog>
);

ConfirmationDialog.defaultProps = {
  actionLabel: 'Ok',
  cancelLabel: 'Cancel',
  disabledActionLabel: false,
  width: 'small',
  customActions: null,
  onOKClick: () => {},
  onCancelClick: () => {}
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onOKClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  actionLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  disabledActionLabel: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  PaperProps: PropTypes.object,
  customActions: PropTypes.node,
  okButtonProps: PropTypes.object,
  cancelButtonProps: PropTypes.object
};

export default ConfirmationDialog;
