import actionTypes from './ImportStartConstants';

const initialStore = {
  startingImport: false,
  cancellingImport: false,
  jobId: -1,
  importStartError: false,
  importError: ""
};

const ImportStartReducer = (state = initialStore, action) => {
  switch (action.type) {

    case actionTypes.STARTING_IMPORT:
      return { ...state, startingImport: true, importStartError: false };

    case actionTypes.START_IMPORT_SUCCESS:
      return { ...state, startingImport: false, jobId: action.jobId };

    case actionTypes.START_IMPORT_FAILURE:
      return { ...state, startingImport: false, importStartError: true, importError: action.importError };

    case actionTypes.CANCELLING_IMPORT:
      return { ...state, cancellingImport: true };

    case actionTypes.CANCEL_IMPORT_SUCCESS:
      return { ...state, cancellingImport: false };

    case actionTypes.CANCEL_IMPORT_FAILURE:
      return { ...state, cancellingImport: false };

    default:
      return state;
  }
};

export default ImportStartReducer;
