import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DashboardWrapper from './App';
import { fetchForms, fetchContextInfo } from 'redux/manageUser/ManageUserActions';
import { resetMetadata } from 'shared/dataEntryForms/sovos-dynamic-form/redux/FormMetaInformationActions';
import { setContext, fetchClient } from 'redux/context/contextActions';

const mapStateToProps = state => ({
  selectedContext: state.contextReducer.selectedContext,
  user: state.contextReducer.user,
  contexts: state.contextReducer.contextTree,
  isFetchingForms: state.manageUser.isFetchingForms,
  isFetchingPermission: state.manageUser.isFetchingPermission
});

const mapDispatchToProps = dispatch => ({
  manageUserActions: bindActionCreators({ fetchForms, fetchContextInfo }, dispatch),
  contextActions: bindActionCreators({ setContext, fetchClient }, dispatch),
  formMetaInformationActions: bindActionCreators({ resetMetadata }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardWrapper);
