import { WebStorageStateStore } from 'oidc-client-ts';

const findFirstExistingProperty = (properties, errorMessage) => {
  const notEmptyProperties = properties.filter(property => property !== undefined);
  if (notEmptyProperties.length === 0) {
    throw new Error(errorMessage);
  }
  return notEmptyProperties[0];
};

const resolveDynamicValues = properties => findFirstExistingProperty(
  properties,
  'Failed to resolve dynamic name. Please check .env file.'
);

const ADMIN_PORTAL_URL = resolveDynamicValues([window.env?.REACT_APP_ADMIN_PORTAL_URL, process.env.REACT_APP_ADMIN_PORTAL_URL]);

const COGNITO_ACCOUNT_URL = resolveDynamicValues([window.env?.REACT_APP_COGNITO_ACCOUNT_URL, process.env.REACT_APP_COGNITO_ACCOUNT_URL]);
const COGNITO_CLIENT = resolveDynamicValues([window.env?.REACT_APP_COGNITO_CLIENT, process.env.REACT_APP_COGNITO_CLIENT]);
const COGNITO_ISSUER_URL = resolveDynamicValues([window.env?.REACT_APP_COGNITO_ISSUER_URL, process.env.REACT_APP_COGNITO_ISSUER_URL]);

const PING_ACCOUNT_URL = resolveDynamicValues([window.env?.REACT_APP_PING_ACCOUNT_URL, process.env.REACT_APP_PING_ACCOUNT_URL]);
const PING_CLIENT = resolveDynamicValues([window.env?.REACT_APP_PING_CLIENT, process.env.REACT_APP_PING_CLIENT]);
const PING_ISSUER_URL = resolveDynamicValues([window.env?.REACT_APP_PING_ISSUER_URL, process.env.REACT_APP_PING_ISSUER_URL]);

const cognitoOidcConfig = {
  authority: `${COGNITO_ACCOUNT_URL}`,
  issuer: COGNITO_ISSUER_URL,
  authorization_endpoint: `${COGNITO_ACCOUNT_URL}/api/oauth/authorize`,
  token_endpoint: `${COGNITO_ACCOUNT_URL}/api/oauth/token`,
  jwks_uri: `${COGNITO_ACCOUNT_URL}/.well-known/jwks`,
  client_id: COGNITO_CLIENT,
  userStore: new WebStorageStateStore({ store: window.localStorage })
};

const pingOidcConfig = {
  authority: `${PING_ACCOUNT_URL}`,
  issuer: PING_ISSUER_URL,
  authorization_endpoint: `${PING_ACCOUNT_URL}/as/authorization.oauth2`,
  token_endpoint: `${PING_ACCOUNT_URL}/as/token.oauth2`,
  jwks_uri: `${PING_ACCOUNT_URL}/pf/JWKS`,
  client_id: PING_CLIENT
};

const OIDC_CONFIG = {
  cognitoOidcConfig,
  pingOidcConfig
};

export {
  ADMIN_PORTAL_URL,
  OIDC_CONFIG
};