/* eslint react/no-multi-comp:0 */
import { createElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormFieldConnector from '../formField/FormFieldConnector';
import AuthenticationHelper from 'helpers/authenticationHelper';
import * as conditions from '../helper/conditionsHelper';
import { noFormsFchars } from '../../../../helpers/formConstants.js';
import CollapsibleListFields from 'shared/dataEntryForms/formContainer/formDetailsComponents/collapsibleListFields/CollapsibleListFields';

const DynamicFormComponent = ({ taxEntityCode, metadata, type, getFieldValue, readOnlyUser, forceReadOnly }) => {
  const formColumns = ['panels', 'fields'];
  const formContainers = ['rows'];
  const columnParent = { allowedChilds: formColumns };
  const containerParent = { allowedChilds: formContainers };

  const components = {
    root: containerParent,
    rows: columnParent,
    panels: containerParent,
    fields: {
      render: FormFieldConnector,
      allowedChilds: []
    }
  };

  const childrenElementName = Object.keys(metadata).find(element => Object.keys(components).includes(element));
  const isPanelsOrFields = formColumns.includes(childrenElementName);
  const isEntityPanels = childrenElementName === 'panels' && noFormsFchars.includes(taxEntityCode);
  const isTaxFormPanels = childrenElementName === 'panels' && !noFormsFchars.includes(taxEntityCode);

  const createChildren = metadata => createElement(components[childrenElementName].render || DynamicFormComponent, {
    metadata,
    type: childrenElementName,
    //TODO: Refactor to use below props from context
    taxEntityCode: taxEntityCode,
    getFieldValue,
    readOnlyUser,
    forceReadOnly
  });

  const createCollapsibleFieldsHeader = metadata => createElement(FormFieldConnector, {
    metadata,
    type: childrenElementName,
    taxEntityCode: taxEntityCode,
    getFieldValue,
    readOnlyUser,
    forceReadOnly
  });

  const showChildren = node => {
    if (node.hasOwnProperty('show')) {
      if (node.show.hasOwnProperty('showConditions') && node.show.hasOwnProperty('operator')) {
        return node.show.showConditions.map(showCondition => {
          const { field, condition, value } = showCondition;
          return conditions[condition](getFieldValue(field), value);
        }).reduce((acc, nextVal) => conditions[node.show.operator](acc, nextVal));
      }
      const { condition, field, value } = node.show;
      return conditions[condition](getFieldValue(field), value);
    }
    return true;
  };

  const createChildrenContainerElement = (childrenContent, index) =>
    childrenContent.collapsible
      ? createElement(
        CollapsibleListFields,
        {
          header: createCollapsibleFieldsHeader(childrenContent.fields[0]),
          key: childrenContent.key || index
        },
        createChildren(childrenContent)
      )
      : createElement(
        'div',
        {
          label: childrenContent.title,
          className: classNames(
          `sovos-dynamic-form__${childrenElementName}`,
          {
            [`xs-${childrenContent.width || 12} xs-offset-${childrenContent.offset || 0}`]: isPanelsOrFields,
            'sovos-dynamic-form__entity': isEntityPanels,
            'sovos-dynamic-form__tax-form': isTaxFormPanels
          }
          ),
          key: childrenContent.key || index
        },
        createChildren(childrenContent)
      );

  const checkConditionFields = conditionFields =>
    Object.entries(conditionFields)
      .map(([key, value]) => getFieldValue(key) === value)
      .some(x => x);

  const createChildrenContainer = metadata =>
    metadata.map((childrenContent, index) =>
      (childrenContent.hide || !showChildren(childrenContent) || (Boolean(childrenContent.showOnConditionField) && !checkConditionFields(childrenContent.showOnConditionField)))
        ? createElement('span', { key: childrenContent.key || index })
        : AuthenticationHelper.verifyFieldProductMatch(childrenContent?.permissions)
          && AuthenticationHelper.checkAllUserPermissions(childrenContent?.permissions)
          && AuthenticationHelper.checkAllConfigurations(childrenContent?.configurations)
          && createChildrenContainerElement(childrenContent, index)
    );

  return (
    <div className="sovos-dynamic-form__dynamic-form-component">
      { metadata.title && <h4>{ metadata.title }</h4> }
      {
        (components[type].allowedChilds.indexOf(childrenElementName) >= 0) &&
          createChildrenContainer(metadata[childrenElementName])
      }
    </div>
  );
};

DynamicFormComponent.propTypes = {
  metadata: PropTypes.object.isRequired,
  taxEntityCode: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  readOnlyUser: PropTypes.bool.isRequired,
  forceReadOnly: PropTypes.bool
};

export default DynamicFormComponent;
