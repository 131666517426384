import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import OrderStatusTable from 'shared/orderStatusTable/OrderStatusTable';
import AuthenticationHelper from 'helpers/authenticationHelper';
import { STATE_REPORTING } from 'helpers/configurationConstants';
import {
  federalColumns,
  getStateColumns,
  EOTColumns,
  transmitType as transmitTypeConst
} from '../../pages/transmitCorp/helpers/configTransmitStatus';
import { replaceStatusWording } from 'helpers/statusWordingHelper';
import { withSnackbar } from 'enhancers';
import { getErrorMessage } from 'helpers/errorMessageHelper';
import { fetchStateTransmit } from 'helpers/corpStateTransmitHelper';
import { FE_TRANSMIT_001 } from 'helpers/errorMessageConstants';
import { get } from 'helpers/axios/axiosInterceptorsV1';
import { getLocalDate, getLocalTimestamp } from 'tir-frontend-common/helpers/dateHelper';
import { buildUrlsParams } from 'helpers/buildUrlParams';
import usePaginationForFetch from 'helpers/usePaginationForFetch';
import useColumnSort from 'helpers/useColumnSort';

const EOTFormTypes = {
  2: '1099',
  3: '5498',
  4: '1042-S'
};

const TransmitHistoryTable = ({
  transmitType,
  showErrorSnackbar,
  enableSorting,
  enablePagination,
  showMoreButton,
  showMoreUrl,
  noResultsMessage,
  pageSizeDefault,
  title,
  configViewAllButton,
  breadcrumbs,
  className
}) => {
  const [fetchingData, setFetchingData] = useState(false);
  const [stateTransmitRecord, setStateTransmitRecord] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [transmittalsData, setTransmittalsData] = useState({ data: [], total: 0 });

  const {
    currentPage,
    itemsPerPage,
    handlePageChanged
  } = usePaginationForFetch(transmittalsData.total, pageSizeDefault);

  const {
    sortOrderIndicator,
    sortColumnIndicator,
    sortColumnId,
    onColumnSortChanged
  } = useColumnSort();

  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;

    const [stateReporting] = AuthenticationHelper.checkConfiguration([STATE_REPORTING]);
    const corporateStateReporting = stateReporting && AuthenticationHelper.isTIRCorporate();

    if (corporateStateReporting && transmitType === transmitTypeConst.state) {

      const fetchStateTransmitRecords = async () => {
        try {
          setFetchingData(true);
          const result = await fetchStateTransmit(currentPage, itemsPerPage);

          if (mounted) {
            setStateTransmitRecord([...result.Data]);
            setTotalRecords(result.Total);
          }
        } catch (error) {
          showErrorSnackbar({ message: getErrorMessage(null, FE_TRANSMIT_001) });
        }
        setFetchingData(false);
      };

      fetchStateTransmitRecords();
    }

    return () => {
      mounted = false;
    };
  }, [itemsPerPage, currentPage]);

  const formatFederalTransmitHistory = data => data.map(record => {
    let displayType = '';
    if (record.FormsToRun) {
      if (record.FormsToRun.length === 1) {
        displayType = record.FormsToRun[0];
      }
      else if (record.FormsToRun.length > 1) {
        displayType = `Multiple (${record.FormsToRun.length})`;
      }
    }

    return {
      ...record,
      DisplayFormType: displayType,
      DisplayStatus: `${replaceStatusWording(record.Status)} ${getLocalTimestamp(record.UpdatedOn)}`
    };
  });

  const formatEOTTransmitHistory = data => data.map(data => ({
    ...data,
    FormType: EOTFormTypes[data.FormType],
    CreatedOn: getLocalDate(data.CreatedOn)
  }));

  useEffect(() => {
    if ([transmitTypeConst.federal, transmitTypeConst.EOT].includes(transmitType)) {
      const fetchTransmitHistory = async () => {
        const fetchUrl = transmitType === transmitTypeConst.federal
          ? 'transmits/forms/history?'
          : 'transmittals/extensionOfTime/history?';
        try {
          setFetchingData(true);
          const parsedQueryString = buildUrlsParams({ pageSize: itemsPerPage, pageOffset: currentPage, sortBy: sortOrderIndicator + sortColumnIndicator });
          const response = await get(`${fetchUrl}${parsedQueryString}`);
          const formattedData = transmitType === transmitTypeConst.federal
            ? formatFederalTransmitHistory(response.data.data.Data)
            : formatEOTTransmitHistory(response.data.Data);
          const total = transmitType === transmitTypeConst.federal
            ? response.data.data.Total
            : response.data.Total;

          setTransmittalsData({
            total: total,
            data: formattedData
          });
        } catch (error) {
          showErrorSnackbar({ message: getErrorMessage(null, FE_TRANSMIT_001) });
        }
        setFetchingData(false);
      };

      fetchTransmitHistory();
    }
  }, [itemsPerPage, currentPage, sortOrderIndicator, sortColumnIndicator]);

  const getSortingProps = () => {
    if (enableSorting && [transmitTypeConst.federal, transmitTypeConst.EOT].includes(transmitType)) {
      return ({
        onColumnSortChanged,
        sortColumnIndicator,
        sortColumnId
      });
    };
    return null;
  };

  const getPaginationProps = () => enablePagination ? {
    onPageChange: handlePageChanged,
    itemsPerPage,
    currentPage: currentPage,
    totalItems: [transmitTypeConst.federal, transmitTypeConst.EOT].includes(transmitType) ? transmittalsData.total : totalRecords
  } : null;

  const getTableColumns = () => {
    switch (transmitType) {
      case transmitTypeConst.federal:
        return federalColumns;
      case transmitTypeConst.state:
        return getStateColumns(true);
      case transmitTypeConst.EOT:
        return EOTColumns;
      default:
        return [];
    }
  };

  const handleRowClick = row => {
    if (row.FORMS !== 0) {
      navigate(`/transmit-history/${transmitType}/detail/${row.TransmitId}`, {
        state: {
          FilingStatus: row.FilingStatus,
          breadcrumbs: breadcrumbs
        }
      });
    }
  };

  return (
    <OrderStatusTable
      title={ title }
      configViewAllButton={ configViewAllButton }
      className={ className }
      columns={ getTableColumns() }
      data={ [transmitTypeConst.federal, transmitTypeConst.EOT].includes(transmitType) ? transmittalsData.data : stateTransmitRecord }
      isUpdating={ fetchingData }
      paginationFooterProps={ getPaginationProps() }
      showMoreButton={ showMoreButton }
      showMoreUrl={ showMoreUrl }
      noResultsMessage={ noResultsMessage }
      onRowClick={ transmitType === transmitTypeConst.federal ? handleRowClick : () => {} }
      { ...getSortingProps() }
      breadcrumbs={ breadcrumbs }
    />
  );
};

TransmitHistoryTable.propTypes = {
  transmitType: PropTypes.oneOf([transmitTypeConst.federal, transmitTypeConst.state, transmitTypeConst.EOT]),
  enablePagination: PropTypes.bool,
  enableSorting: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showMoreUrl: PropTypes.string,
  noResultsMessage: PropTypes.string,
  pageSizeDefault: PropTypes.number,
  title: PropTypes.string,
  configViewAllButton: PropTypes.object,
  className: PropTypes.string,
  showErrorSnackbar: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.array
};

TransmitHistoryTable.defaultProps = {
  enablePagination: true,
  enableSorting: true,
  showMoreButton: false,
  pageSizeDefault: 20,
  breadcrumbs: []
};

export default withSnackbar(TransmitHistoryTable);
