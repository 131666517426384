import DataModifier from 'shared/dataModifier/DataModifier';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import specialFields from 'helpers/specialFields';
import {
  the499R2Fchar,
  theW2Fchar,
  payerFchar,
  recipientFchar,
  recipient1042SFchar,
  theW2MFchar
} from 'helpers/formConstants';
import './sovosDynamicForm.scss';

const W2M_NON_SORTABLE_COLUMNS = ['ALTSTATE', 'WITHHOLDING', 'PAID', 'QTRLOCAL', 'AUXAMT1', 'AUXAMT2', 'TAXTYPE', 'COUNTYCODE', 'PSDRESIDENCE', 'PSDWORKLOC', 'LOCALITYSTATE', 'LOCALITYCODE'];

const getDataAttribute = (fieldId, fieldValue) => ({ [`data-${fieldId.replace(/(\s|\/|\)|\(|'|’|,|%|\$|=|>)/g, '-').toLowerCase()}`]: fieldValue });

const getColumns = enabledFields => (
  enabledFields.length
    ? enabledFields.map((field, id) => (
      {
        id,
        title: field['label'],
        sortIndicator: field.key,
        component: ({ content }) => <span { ...getDataAttribute(field['label'], content[field.key]) }>{ content[field.key] }</span>,
        width: 'greedy'
      }
    ))
    : []
);

const getTableValues = (tableHeaders, records, metaDataFields, fchar) => (
  records && metaDataFields
    ? records.map((record, index) => {
      const is1042SForm = metaDataFields?.FK2?.label === 'TIN';
      customizeMetaDataFields(record, metaDataFields);
      let rows = tableHeaders.map(header => {
        const key = header.key;
        const value = header.modifier
          ? <DataModifier modifierType={ header.modifier } records={ record } is1042SForm={ is1042SForm } fchar={ fchar } />
          : record[key] || '';
        const row = {};
        row[key] = value;
        return row;
      }
      );
      rows = rows.reduce((acc, cur) => {
        const key = Object.keys(cur)[0];
        acc[key] = cur[key];
        return acc;
      }, {});
      return { index, ...rows };
    })
    : []
);

const getColumnLabels = (tableHeaders, metadata, isEnabled) => {
  const headers = [];
  let order;
  const findKey = (key, haystack) => {
    Array.isArray(haystack) ? haystack.forEach(children => { // eslint-disable-line
      children.hasOwnProperty('fields') ? children.fields.forEach(field => { // eslint-disable-line
        if (field.dbColumn === key && field.name.search(specialFields.IDENTITYVERIFICATION) !== 0) {
          order = tableHeaders[key].order;
          headers[order] = {
            name: field.name,
            key,
            label: field.label,
            order,
            isEnabled,
            dataModifier: field.dataModifier || null,
            headerModifier: field.headerModifier || null,
            entityField: field.entityField || null,
            viewColumn: field.viewColumn || null
          };
        }
      }) : findKey(key, children);
    }) : getChildrenByKeys(haystack, findKey, key);
  };
  Object.keys(tableHeaders).forEach(header => {
    findKey(header, metadata);
  });
  return headers.filter(column => column);
};

const getChildrenByKeys = (haystack, findKey, key) => (
  typeof haystack === 'object' ? Object.keys(haystack).forEach(children => findKey(key, haystack[children])) : []
);

export const getFilingType = trantype => {
  switch (trantype) {
    case undefined:
    case '':
    case '_':
    case 'O':
      return 'Original';
    case 'C':
      return 'Correction';
    case 'N':
      return 'Name Change';
    case 'T':
      return 'TIN Change';
    default:
      return trantype;
  }
};

export const getReturnStatusType = type => {
  switch (type) {
    case 'H':
    case 'C':
    case 'N':
    case 'T':
    case 'Correction':
      return 'Correction';
    default:
      return 'Original';
  }
};

const printStatus = {
  none: 0,
  notPrinted: 1,
  printed: 2
};

const customizeMetaDataFields = (form, metadataFields) => {
  Object.keys(metadataFields).filter(fieldName => metadataFields[fieldName].mask === 'DATE').forEach(dateField => {
    if (form[dateField]) {
      form[dateField] = moment(form[dateField]).format('MM-DD-YYYY');
    }
  });
  return form;
};

const customizeDateFormat = date => moment(date).format('MM/DD/YYYY');

const supportedTrantypes = ['C', 'N', 'T'];

const showAmountDifferenceField = (trantype, fchar) => {
  const supportedFchars = [theW2Fchar, the499R2Fchar, theW2MFchar];
  return (supportedTrantypes.includes(trantype) && supportedFchars.includes(fchar));
};

const validateToReprint = fchar => ![recipient1042SFchar, payerFchar, recipientFchar].includes(fchar);

export const getFormName = (title, isCustomCorrection) => isCustomCorrection ? title.concat('C') : title;

const getStatusIcons = errorCount =>
  errorCount > 0
    ? ( <CancelIcon className="status-field_icon-errors"/> )
    : ( errorCount === 0
      ? <CheckCircleIcon className="status-field_icon-accepted"/>
      : '' );

const stringifyErrorCodes = errors => errors ? errors.map(error => error.Code).join(', ') : '';

const getCustomizedColumns = columns => {
  const cutomizedColumns = getColumns(columns);
  const tranTypeIndex = cutomizedColumns.findIndex(current => current.sortIndicator === 'TRANTYPE');
  if (tranTypeIndex >= 0) {
    cutomizedColumns[tranTypeIndex].component = ({ content }) => getFilingType(content.TRANTYPE);
  }
  const errorCountIndex = cutomizedColumns.findIndex(current => current.sortIndicator === 'ERRORVALIDATIONCOUNT');
  if (errorCountIndex >= 0) {
    cutomizedColumns[errorCountIndex].component = ({ content }) => getStatusIcons(content.ERRORVALIDATIONCOUNT);
  }

  const errorCodeIndex = cutomizedColumns.findIndex(current => current.sortIndicator === 'ERRORCODES');
  if (errorCodeIndex >= 0) {
    cutomizedColumns[errorCodeIndex].component = ({ content }) => stringifyErrorCodes(content.ERRORCODES);
  }
  return cutomizedColumns;
};

const getCustomCell = (id, value) => {
  switch (id) {
    case 'TRANTYPE':
      return value && getFilingType(value);
    case 'ERRORVALIDATIONCOUNT':
      return getStatusIcons(value);
    default:
      return value;
  }
};

const getCustomizedColumnsNext = fields => (
  fields.length
    ? fields.map(field => ({
      id: field.entityField || field.key,
      field: field.viewColumn || field.key,
      headerName: field.label,
      groupable: false,
      hideable: false,
      pinnable: false,
      filterable: false,
      sortable: !W2M_NON_SORTABLE_COLUMNS.includes(field.viewColumn || field.key),
      width: 200,
      renderCell: params => (<span { ...getDataAttribute(field.label, params.value) }>{ getCustomCell(params.field, params.value) }</span>)
    }))
    : []
);

const prepareW2MtoSave = (form, actionType = 'NotSet') => {
  const RecipientId = form.MODRECNO;
  const PayerId = form.PAYERRECNO;
  const Version = form.MODLEVEL;
  delete form.PAYERID;
  delete form.PAYERRECNO;
  delete form.FK1;
  delete form.FK2;
  delete form.MODRECNO;
  delete form.MODLEVEL;
  return { form: { ...form, RecipientId, PayerId, Version }, actionType };
};

const cleanW2MResponse = form => {
  const MODRECNO = form.RecipientId;
  const PAYERRECNO = form.PayerId;
  const MODLEVEL = form.Version;
  delete form.RecipientId;
  delete form.PayerId;
  delete form.Version;

  deleteEmptyFields(form);
  form.CodedPayments.forEach(cPayments => deleteEmptyFields(cPayments));
  form.OtherValues.forEach(oValues => deleteEmptyFields(oValues));
  form.Localities.forEach(locality => deleteEmptyFields(locality));
  form.States.forEach(state => deleteEmptyFields(state));

  return { ...form, MODRECNO, PAYERRECNO, MODLEVEL };
};

const injectFormFilingStatusToFormInfo = (formInfo, formFilingStatus ) => {
  let localities = formInfo.Localities;
  let states = formInfo.States;

  if (formFilingStatus.Locality) {
    formFilingStatus.Locality.forEach(locality => {
      const index = localities.findIndex(item =>
        item.LocalityName === locality.LocalityName
        && item.StateAbbreviation === locality.State
        && item.TaxType === locality.TaxType
        && (item.CustomFieldsData?.ResidencePSD ?? null) === locality.ResidencePSD
        && (item.CustomFieldsData?.WorkLocationPSD ?? null) === locality.WorkLocationPSD
      );
      localities[index] = { ...localities[index], ReturnStatus: locality.ReturnStatus, Xmitrun: locality.Xmitrun };
    });
  }

  if (formFilingStatus.State) {
    formFilingStatus.State.forEach(state => {
      const index = states.findIndex(item => item.StateAbbreviation === state.State);
      states[index] = { ...states[index], ReturnStatus: state.ReturnStatus, Xmitrun: state.Xmitrun };
    });
  }

  return { ...formInfo, Localities: localities, States: states };
};

const deleteEmptyFields = object => {
  Object.keys(object).forEach(key => {
    if (!object[key]) {
      delete object[key];
    }
  });
};

const filterFieldsByShowOnSearch = (fields, option) => Object.entries(fields)
  .filter(([key, value]) => option ? value.showOnSearch : !(value.showOnSearch))
  .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

export default {
  getColumns,
  getTableValues,
  getDataAttribute,
  getColumnLabels,
  printStatus,
  customizeMetaDataFields,
  showAmountDifferenceField,
  validateToReprint,
  getCustomizedColumns,
  getCustomizedColumnsNext,
  prepareW2MtoSave,
  cleanW2MResponse,
  injectFormFilingStatusToFormInfo,
  filterFieldsByShowOnSearch,
  supportedTrantypes,
  customizeDateFormat
};
