import { get, post } from 'helpers/axios/axiosInterceptorsV1';
import actionTypes from './RecordConstants';
import { getRecordName, isTaxForm, getEntityPath } from 'helpers/formConstants';
import { buildUrlsParams } from 'helpers/buildUrlParams';
import { downloadFileByPath } from 'redux/fileManager/files/FileManagerFilesActions';

const getPath = (recordType, status, query, irsCode) => {
  switch (recordType) {
    case '0':
      return `/payers/${ status || 'search' }?${buildUrlsParams(query)}`;

    case '1':
      return irsCode
        ? `/payees/tin-mismatches/${irsCode}${ status ? `/${status}` : ''}?${buildUrlsParams(query)}`
        : `/payees/${ status || 'search' }?${buildUrlsParams(query)}`;

    case 'N':
      return `/npayees/${ status || 'search' }?${buildUrlsParams(query)}`;

    default:
      return `/tax-forms/${ status || 'search' }?${buildUrlsParams(query)}`;
  }
};

const resetRecords = () => dispatch => dispatch({ type: actionTypes.RESET_RECORDS });

const fetchAllRecords = (recordType, modId, status, searchText, pageOffset, pageSize, sortColumn, sortOrder, isTestImport, validationId) => async dispatch => {
  dispatch({ type: actionTypes.FETCHING_RECORDS });
  const query = Object.assign(
    {
      pageSize,
      pageOffset,
      sortBy: sortOrder + sortColumn
    },
    recordType !== '1'
      ? { fchar: recordType }
      : {},
    modId > -1
      ? { modId: `#${modId}` }
      : { getAll: true },
    validationId
      ? { validationId }
      : {}
  );

  try {
    const testURL = isTestImport ? '/test-imports' : '';
    const path = `${testURL}${getPath(recordType, status, query)}`;
    const response = await post(path, { SearchText: searchText });
    dispatch({ type: actionTypes.FETCH_RECORDS_SUCCESS, records: response.data });
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_RECORDS_FAILURE });
    throw (error);
  }
};

const getSortQueryParam = (sortColumn, isAscending) => sortColumn ? `${ isAscending ? '+' : '-' }${ sortColumn }` : '';

const fetchAllErrors = (modId, taxDetailStatus, pageSize, pageOffset, sortColumn, isAscending, isTestImport) => async dispatch => {
  const query = Object.assign(
    { pageSize, pageOffset, sortBy: getSortQueryParam(sortColumn, isAscending) },
    modId ? { modId: `#${modId}` } : {},
    taxDetailStatus ? { taxDetailStatus } : {}
  );
  const testURL = isTestImport ? '/test-imports' : '';
  const path = `${testURL}/tax-entities/details?${buildUrlsParams(query)}`;

  dispatch({ type: actionTypes.FETCHING_ERRORS_RECORDS });

  try {
    const response = await get(path);
    dispatch({ type: actionTypes.FETCH_ERRORS_RECORDS_SUCCESS, records: response.data });
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_ERRORS_RECORDS_FAILURE });
    throw (error);
  }
};

const fetchAllFailed = (recordType, modId, searchText, isTestImport) => async dispatch => {
  const query = Object.assign(
    modId ? { modId: `#${modId}` } : {},
    recordType !== '1'
      ? { fchar: recordType }
      : {}
  );
  const testURL = isTestImport ? '/test-imports/' : '';
  const path = `${testURL}${getEntityPath(recordType)}/failed?${buildUrlsParams(query)}`;

  dispatch({ type: actionTypes.FETCHING_FAILED_RECORDS });

  try {
    const response = await post(path, { SearchText: searchText });
    dispatch({ type: actionTypes.FETCH_FAILED_RECORDS_SUCCESS, records: response.data });
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_FAILED_RECORDS_FAILURE });
    throw (error);
  }
};

const getName = (modId, irsCode, fchar, splitPath, type) => {
  if (modId) {
    if (type !== "error") {
      return 'RECORDS';
    }
    return 'FAILURES';
  } else if (irsCode) {
    return 'TINMISMATCHES';
  } else if (isTaxForm(fchar)) {
    return getRecordName(fchar);
  } else {
    const pathPart3 = splitPath[3].toUpperCase().split('?')[0].toUpperCase();
    const pathPart4 = splitPath[4] ? `+ ${splitPath[4].split('?')[0].toUpperCase()}` : '';
    return `${pathPart3} ${pathPart4}`;
  }
};

const exportRecords = (fchar, type, validationId, searchText, irsCode, modId, isTestImport, switchToFilingStatus) => async dispatch => {
  const query = Object.assign(
    { pageSize: 1000000, parseColumnNames: true },
    !['0', '1'].includes(fchar)
      ? { fchar: fchar }
      : {},
    !searchText
      ? { getAll: true }
      : {},
    validationId
      ? { validationId: validationId }
      : {},
    irsCode
      ? { auto: false, parseColumnNames: true, getAll: true }
      : {},
    modId
      ? { modId: `#${modId}`, parseColumnNames: false }
      : {},
    type === 'failed'
      ? { parseColumnNames: true }
      : {},
    switchToFilingStatus
      ? { switchToFilingStatus: true }
      : {}
  );
  const urlTest = isTestImport ? '/test-imports' : '';
  const url = `/api/v1${urlTest}${getPath(fchar, type, query, irsCode)}`;
  const splitPath = url.split('/');
  const name = getName(modId, irsCode, fchar, splitPath, type);
  const payload = {
    FileName: name.replace(/\s+/g, ''),
    DataRequests: [
      {
        name,
        url,
        method: 'POST',
        body: { SearchText: searchText }
      }
    ]
  };
  try {
    dispatch({ type: actionTypes.EXPORT_RECORDS_STARTED });
    const response = await post('/reports', payload);
    const filePathParts = response.data.data.split('\\');
    const file = {
      path: response.data.data,
      name: filePathParts[filePathParts.length - 1]
    };
    dispatch(downloadFileByPath(file));
    dispatch({ type: actionTypes.EXPORT_RECORDS_FINISHED });
  } catch (error) {
    dispatch({ type: actionTypes.EXPORT_RECORDS_FAILED, exportError: error });
  }
};

const fetchAllRecordsByValidationId = (
  modId,
  validationId,
  recordType,
  status,
  searchText,
  pageOffset,
  pageSize,
  taxDetailStatus,
  sortColumn,
  sortOrder,
  isTestImport
) => async dispatch => {
  dispatch({ type: actionTypes.FETCHING_RECORDS });
  const queryObject = Object.assign({
    modId: modId ? `#${modId}` : null,
    validationId,
    pageOffset,
    pageSize,
    sortBy: sortOrder + sortColumn
  },
  isTaxForm(recordType) ? {
    fchar: recordType,
    taxDetailStatus
  } : {});

  const testURL = isTestImport ? '/test-imports' : '';
  const path = `${testURL}${getPath(recordType, status, queryObject)}`;

  try {
    const response = await post(path, { SearchText: searchText });
    dispatch({ type: actionTypes.FETCH_RECORDS_SUCCESS, records: response.data || { Data: [], Total: 0 } });
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_RECORDS_FAILURE });
    throw (error);
  }
};

const fetchFormsByStatus = (
  taxDetailStatus,
  searchText,
  pageOffset,
  pageSize,
  sortColumn,
  sortOrder
) => async dispatch => {
  dispatch({ type: actionTypes.FETCHING_RECORDS });

  const query = {
    pageOffset,
    pageSize,
    sortBy: sortOrder + sortColumn,
    getAll: true,
    defaultFields: true,
    taxDetailStatus
  };
  const path = `/tax-forms/search?${buildUrlsParams(query)}`;

  try {
    const response = await post(path, { SearchText: searchText });
    dispatch({ type: actionTypes.FETCH_RECORDS_SUCCESS, records: response.data });
  } catch (error) {
    dispatch({ type: actionTypes.FETCHING_FAILED_RECORDS });
  }
};

export {
  fetchAllRecords,
  fetchAllErrors,
  fetchAllFailed,
  resetRecords,
  exportRecords,
  fetchAllRecordsByValidationId,
  fetchFormsByStatus
};
