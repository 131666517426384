import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RecipientInformation from './RecipientInformation';
import * as RecipientActions from 'redux/recipient/RecipientActions';
import { setRecipientPayer } from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormActions';
import { theW2MFchar } from 'helpers/formConstants';

const mapStateToProps = (state, ownProps) => ({
  recipientID: state.dynamicForm.form[ownProps.taxEntityCode]?.MODRECNO,
  recipientInformation: state.recipient.data,
  recipients: state.recipient.recipients,
  fetchingRecipient: state.recipient.fetching,
  hideChangeButton: Boolean(ownProps.taxEntityCode === theW2MFchar && (state.dynamicForm.isClone ? state.dynamicForm.cloneForm.RECNO : state.dynamicForm.form[ownProps.taxEntityCode]?.RECNO))
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(RecipientActions, dispatch),
  formActions: bindActionCreators({ setRecipientPayer }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipientInformation);
