import { isNoticeType, getNoticeName } from 'pages/printCorp/helpers/notice/NoticeConstants';
import AuthenticationHelper from 'helpers/authenticationHelper';
import { transmittalPage } from 'helpers/enums';

const payerFchar = '0';
const recipientFchar = '1';
const recipient1042SFchar = 'N';
const noFormsFchars = [payerFchar, recipientFchar, recipient1042SFchar];
const payeesFChars = [recipientFchar, recipient1042SFchar];
const the1042SFchar = 'F';
const theW2VIFchar = 'WVI';
const theW2Fchar = 'W';
const theW2MFchar = 'W2M';
const theNECFchar = 'X9N';
const the499R2Fchar = '6';
const allFormsFchar = 'ATF';
const the499R2NameForFederal = 'W-2PR';
const the499R2NameForFederalCorrection = 'W-2CPR';
const the945FormTaxEntityCode = '945';
const the1042FormTaxEntityCode = '1042';
const withholdingForms = [the945FormTaxEntityCode, the1042FormTaxEntityCode];
const FORMS = 'forms';
const w2Enabled = () => AuthenticationHelper.checkAllConfigurations(['w2']);
const w2MEnabled = () => AuthenticationHelper.checkAllConfigurations(['w2m']);
const statesWithoutCorrection = ['CA', 'FL', 'NY', 'TN', 'VI'];
const mailingAddressAutoCheckBoxFields = [
  'MailingName',
  'MailingName2',
  'MailingAddress',
  'MailingAddress2',
  'MailingCity',
  'MailingState',
  'MailingZip',
  'MailingCountryCode',
  'MailingProvinceCode',
  'MailingPaddress',
  'MailingPaddress2',
  'MailingPcity',
  'MailingPstate',
  'MailingPzip'
];

const formsAvailableByCondition = transmittalType => {
  switch (transmittalType) {
    case transmittalPage.FEDERAL:
      return [
        {
          fchar: theW2Fchar,
          sinceTaxYear: 2018,
          configurationsNeeded: w2Enabled()
        },
        {
          fchar: theNECFchar,
          sinceTaxYear: 2020
        },
        {
          fchar: theW2MFchar,
          sinceTaxYear: 2022,
          configurationsNeeded: w2MEnabled()
        }
      ];
    case transmittalPage.STATE:
      return [
        {
          fchar: theNECFchar,
          sinceTaxYear: 2020
        },
        {
          fchar: the499R2Fchar,
          sinceTaxYear: 2021,
          configurationsNeeded: w2Enabled()
        },
        {
          fchar: theW2Fchar,
          sinceTaxYear: 2018,
          configurationsNeeded: w2Enabled()
        },
        {
          fchar: theW2VIFchar,
          sinceTaxYear: 2021,
          configurationsNeeded: w2Enabled()
        },
        {
          fchar: the1042SFchar,
          disabled: true
        }
      ];
    case transmittalPage.LOCALITY:
      return [
        {
          fchar: theW2Fchar,
          configurationsNeeded: w2Enabled()
        }
      ];
    default:
      return [];
  }
};

const filterFormsToDisplay = (formTypes, transmittalType) => (
  formTypes.filter(fchar => {
    const conditionsForm = formsAvailableByCondition(transmittalType).find(form => form.fchar === fchar);
    const taxYear = AuthenticationHelper.getTaxYear();
    if (conditionsForm) {
      const disabled = conditionsForm.disabled;
      const sinceTaxYear = conditionsForm.sinceTaxYear ? taxYear >= conditionsForm.sinceTaxYear : true;
      const rolDevRequired = conditionsForm.rolDevRequired ? AuthenticationHelper.isRoleDev() : true;
      const configurationsNeeded = conditionsForm.configurationsNeeded !== undefined ? conditionsForm.configurationsNeeded : true;
      const conditions = [!disabled, sinceTaxYear, rolDevRequired, configurationsNeeded];
      return (conditions.every(condition => condition));
    } else {
      return true;
    }
  })
);

const theNecFormInfo = { fchar: 'X9N', sinceTaxYearAvailable: 2020 };
const the499R2FormInfo = { fchar: the499R2Fchar, sinceTaxYearAvailable: 2021 };
const non1099Forms = [the499R2Fchar, theW2VIFchar, theW2Fchar, theW2MFchar, the1042SFchar];
const w2Forms = [theW2Fchar, theW2MFchar];
const formsEligibleAsCorrections = [theW2Fchar, theW2VIFchar, the499R2Fchar, theW2MFchar];
const taxEntityType = {
  W: {
    value: 27,
    typeOf: 'FormW2',
    name: 'W-2',
    correctedName: 'W-2C',
    settingsField: {
      entFederalTransmittalEmploymentCode: 'entFederalTransmittalEmploymentCode',
      entFederalTransmittalPin: 'entFederalTransmittalPin',
      entFederalTransmittalCommonPayAgentCode: 'entFederalTransmittalCommonPayAgentCode',
      entFederalTransmittalCommonPayAgentEin: 'entFederalTransmittalCommonPayAgentEin',
      entFederalTransmittalRecordsResubmitted: 'entFederalTransmittalRecordsResubmitted',
      entFederalTransmittalResubmittedWageFileIdent: 'entFederalTransmittalResubmittedWageFileIdent',
      entFederalTransmittalThirdPartySickPayParticip: 'entFederalTransmittalThirdPartySickPayParticip',
      entFederalTransmittalIncludeRSRecords: 'entFederalTransmittalIncludeRSRecords',
      entStateTransmittalEmploymentCode: 'entStateTransmittalEmploymentCode',
      entStateTransmittalPin: 'entStateTransmittalPin',
      entStateTransmittalCommonPayAgentCode: 'entStateTransmittalCommonPayAgentCode',
      entStateTransmittalCommonPayAgentEin: 'entStateTransmittalCommonPayAgentEin',
      entStateTransmittalRecordsResubmitted: 'entStateTransmittalRecordsResubmitted',
      entStateTransmittalResubmittedWageFileIdent: 'entStateTransmittalResubmittedWageFileIdent',
      entStateTransmittalThirdPartySickPayParticip: 'entStateTransmittalThirdPartySickPayParticip',
      entLocalityTransmittalResubmittedWageFileIdent: 'entLocalityTransmittalResubmittedWageFileIdent',
      entLocalityTransmittalRecordsResubmitted: 'entLocalityTransmittalRecordsResubmitted',
      entLocalityTransmittalEmploymentCode: 'entLocalityTransmittalEmploymentCode',
      entLocalityTransmittalThirdPartySickPayParticip: 'entLocalityTransmittalThirdPartySickPayParticip',
      entLocalityTransmittalCommonPayAgentEin: 'entLocalityTransmittalCommonPayAgentEin',
      entLocalityTransmittalCommonPayAgentCode: 'entLocalityTransmittalCommonPayAgentCode',
      entLocalityTransmittalPin: 'entLocalityTransmittalPin',
      entStateTransmittalGroupFilesByStateId: 'entStateTransmittalGroupFilesByStateId'
    },
    code: 'W'
  },
  6: {
    value: 43,
    typeOf: 'Form499R2',
    name: '499R-2',
    correctedName: '499R-2C',
    settingsField: {
      entFederalTransmittalEmploymentCode: 'entFederalTransmittalEmploymentCode',
      entFederalTransmittalPin: 'entFederalTransmittalPin',
      entFederalTransmittalRecordsResubmitted: 'entFederalTransmittalRecordsResubmitted',
      entFederalTransmittalResubmittedWageFileIdent: 'entFederalTransmittalResubmittedWageFileIdent',
      entFederalTransmittalThirdPartySickPayParticip: 'entFederalTransmittalThirdPartySickPayParticip',
      entStateTransmittalEmploymentCode: 'entStateTransmittalEmploymentCode',
      entStateTransmittalPin: 'entStateTransmittalPin',
      entStateTransmittalRecordsResubmitted: 'entStateTransmittalRecordsResubmitted',
      entStateTransmittalResubmittedWageFileIdent: 'entStateTransmittalResubmittedWageFileIdent',
      entStateTransmittalThirdPartySickPayParticip: 'entStateTransmittalThirdPartySickPayParticip'
    },
    code: '6'
  },
  WVI: {
    value: 68,
    typeOf: 'FormW2VI',
    name: 'W-2VI',
    correctedName: 'W-2VIC',
    settingsField: {
      entFederalTransmittalEmploymentCode: 'entFederalTransmittalEmploymentCode',
      entFederalTransmittalPin: 'entFederalTransmittalPin',
      entFederalTransmittalRecordsResubmitted: 'entFederalTransmittalRecordsResubmitted',
      entFederalTransmittalResubmittedWageFileIdent: 'entFederalTransmittalResubmittedWageFileIdent',
      entFederalTransmittalThirdPartySickPayParticip: 'entFederalTransmittalThirdPartySickPayParticip',
      entStateTransmittalEmploymentCode: 'entStateTransmittalEmploymentCode',
      entStateTransmittalPin: 'entStateTransmittalPin',
      entStateTransmittalRecordsResubmitted: 'entStateTransmittalRecordsResubmitted',
      entStateTransmittalResubmittedWageFileIdent: 'entStateTransmittalResubmittedWageFileIdent',
      entStateTransmittalThirdPartySickPayParticip: 'entStateTransmittalThirdPartySickPayParticip'
    },
    code: 'WVI'
  },
  W2M: {
    value: 69,
    typeOf: 'FormW2M',
    name: 'W-2M',
    correctedName: 'W-2MC',
    settingsField: {
      entFederalTransmittalEmploymentCode: 'entFederalTransmittalEmploymentCode',
      entFederalTransmittalPin: 'entFederalTransmittalPin',
      entFederalTransmittalCommonPayAgentCode: 'entFederalTransmittalCommonPayAgentCode',
      entFederalTransmittalCommonPayAgentEin: 'entFederalTransmittalCommonPayAgentEin',
      entFederalTransmittalRecordsResubmitted: 'entFederalTransmittalRecordsResubmitted',
      entFederalTransmittalResubmittedWageFileIdent: 'entFederalTransmittalResubmittedWageFileIdent',
      entFederalTransmittalThirdPartySickPayParticip: 'entFederalTransmittalThirdPartySickPayParticip',
      entFederalTransmittalIncludeRSRecords: 'entFederalTransmittalIncludeRSRecords',
      entStateTransmittalEmploymentCode: 'entStateTransmittalEmploymentCode',
      entStateTransmittalPin: 'entStateTransmittalPin',
      entStateTransmittalCommonPayAgentCode: 'entStateTransmittalCommonPayAgentCode',
      entStateTransmittalCommonPayAgentEin: 'entStateTransmittalCommonPayAgentEin',
      entStateTransmittalRecordsResubmitted: 'entStateTransmittalRecordsResubmitted',
      entStateTransmittalResubmittedWageFileIdent: 'entStateTransmittalResubmittedWageFileIdent',
      entStateTransmittalThirdPartySickPayParticip: 'entStateTransmittalThirdPartySickPayParticip',
      entLocalityTransmittalResubmittedWageFileIdent: 'entLocalityTransmittalResubmittedWageFileIdent',
      entLocalityTransmittalRecordsResubmitted: 'entLocalityTransmittalRecordsResubmitted',
      entLocalityTransmittalEmploymentCode: 'entLocalityTransmittalEmploymentCode',
      entLocalityTransmittalThirdPartySickPayParticip: 'entLocalityTransmittalThirdPartySickPayParticip',
      entLocalityTransmittalCommonPayAgentEin: 'entLocalityTransmittalCommonPayAgentEin',
      entLocalityTransmittalCommonPayAgentCode: 'entLocalityTransmittalCommonPayAgentCode',
      entLocalityTransmittalPin: 'entLocalityTransmittalPin'
    },
    code: 'W2M'
  }
};
let records = {};

const getRecords = (update = false) => {
  if (update || !Object.entries(records).length) {
    records = JSON.parse(sessionStorage.getItem(FORMS)) || {};
  }
  return records;
};

const getRecordName = (fchar, getSingularName = true, isFederalAndSSARegime) => {
  const records = getRecords();
  if (fchar) {
    if (fchar === allFormsFchar) {
      return 'All Forms';
    }

    if (records.hasOwnProperty(fchar)) {
      if (isFederalAndSSARegime && fchar === the499R2Fchar) {
        return the499R2NameForFederal;
      } else if (getSingularName) {
        return records[fchar].singularName || records[fchar].name;
      } else {
        return records[fchar].name;
      }
    }

    const formByType = Object.values(records).find(record => record.formType === fchar);
    if (formByType) {
      return (formByType.name);
    }

    const formByTypeFullText = Object.values(records).find(record => 'form' + record.name.split('-').join('').toLowerCase() === fchar.toLowerCase());
    if (formByTypeFullText) {
      return (formByTypeFullText.name);
    }

    if (isNoticeType(fchar)) {
      return getNoticeName(fchar);
    }
  }
  return '';
};

const getFormType = fchar => getRecords()[fchar].formType;

const getFcharByName = name => {
  const records = getRecords();
  return Object.keys(records).find(key => records[key].name === name);
};

const getFcharBySingularName = singularName => {
  const records = getRecords();
  return Object.keys(records).find(key => records[key].singularName === singularName);
};

const getBasePath = fchar => getRecords()[fchar].basePath;

const getEntityPath = fchar => getRecords()[fchar].entityPath;

const getOnSearchPath = fchar => {
  const records = getRecords();
  return `${records[fchar].entityPath || records[fchar].basePath}${records[fchar].searchPath}`;
};

const getKey = fchar => getRecords()[fchar].key;

const isTaxForm = fchar => getRecords()[fchar]?.isTaxForm;

const getPanelTitle = fchar => getRecords()[fchar].panelTitle;

const alphabetizeForms = (a, b) => {
  if (typeof a === 'object') {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
  }
  if (typeof a === 'string') {
    if (getRecordName(a) < getRecordName(b)) {
      return -1;
    }
    if (getRecordName(a) > getRecordName(b)) {
      return 1;
    }
  }
  return 0;
};

const getAllRecords = () => {
  const records = getRecords();
  return Object.keys(records)
    .filter(record => !noFormsFchars.includes(record))
    .map(record => ({ ...records[record], taxEntityType: record }))
    .sort(alphabetizeForms);
};

const getAllFCharsTaxForms = () => Object.keys(getRecords())
  .filter(record => !noFormsFchars.includes(record))
  .sort(alphabetizeForms);

const getFchar1099Records = () => Object.keys(getRecords())
  .filter(record => !noFormsFchars.includes(record) && record !== the1042SFchar)
  .sort(alphabetizeForms);

const get1042SRecords = () => {
  const records = getRecords();
  return Object.keys(records)
    .filter(record => record === the1042SFchar)
    .map(record => ({ ...records[record], taxEntityType: record }))
    .sort();
};

const get1099Records = (formsToExclude = undefined) => {
  const records = getRecords();
  return Object.keys(records)
    .filter(record => !noFormsFchars.includes(record) && record !== the1042SFchar && !formsToExclude?.some(form => form === record))
    .map(record => ({ ...records[record], taxEntityType: record }))
    .sort(alphabetizeForms);
};

const getAllFCharsSorted = () => {
  const sortedForms = Object.keys(getRecords())
    .filter(fChar => !noFormsFchars.includes(fChar))
    .sort(alphabetizeForms);
  const noFormsFcharsIncluded = noFormsFchars.filter(fchar => Object.keys(getRecords()).includes(fchar));
  return [...noFormsFcharsIncluded, ...sortedForms];
};

const getFormConstant = properties => ({
  basePath: 'tax-forms',
  entityPath: 'tax-forms',
  searchPath: '/search',
  key: 'RECNO',
  panelTitle: 'RECNO',
  ...properties
});

const getFormsNames = fchars => fchars.map(fchar => ({ fchar, name: getRecordName(fchar) }));

const formDelete = {
  can: {
    title: 'Delete record',
    message: 'Do you really want to delete this record? This process cannot be undone.',
    actionLabel: 'Delete'
  },
  cant: {
    title: `This record can't be deleted`,
    message: 'This Form record has been printed and/or transmitted and cannot be deleted.'
  }
};

const allRecords = () => ({
  0: getFormConstant({
    name: 'Payers',
    singularName: 'Payer',
    basePath: 'payers',
    entityPath: 'payers',
    searchPath: '/search',
    key: 'ID',
    panelTitle: 'PNAME',
    isTaxForm: false,
    delete: {
      can: {
        title: 'Delete record',
        hasForm: {
          message: 'This Payer record has countForm forms linked to it. Deleting this payer will also delete the linked form records and cannot be undone.'
        },
        hasNoForm: {
          message: 'Do you really want to delete this record? This process cannot be undone.'
        },
        actionLabel: 'Delete'
      },
      cant: {
        title: `This record can't be deleted`,
        message: 'This Payer record has forms linked to it that have been printed and/or transmitted and cannot be deleted.'
      }
    }
  }),
  1: getFormConstant({
    name: 'Recipients',
    singularName: 'Recipient',
    basePath: 'payees',
    entityPath: 'payees',
    searchPath: '/search',
    key: 'MODRECNO',
    panelTitle: 'NAME',
    isTaxForm: false,
    delete: {
      can: {
        title: 'Delete record',
        hasForm: {
          message: 'This Recipient record has countForm forms linked to it. Deleting this recipient will also delete the linked form records and cannot be undone.'
        },
        hasNoForm: {
          message: 'Do you really want to delete this record? This process cannot be undone.'
        },
        actionLabel: 'Delete'
      },
      cant: {
        title: `This record can't be deleted`,
        message: 'This Recipient record has forms linked to it that have been printed and/or transmitted and cannot be deleted.'
      }
    }
  }),
  N: getFormConstant({
    name: '1042-S recipients',
    singularName: '1042-S recipient',
    basePath: 'npayees',
    entityPath: 'npayees',
    searchPath: '/search',
    key: 'MODRECNO',
    panelTitle: 'NAME',
    isTaxForm: false,
    delete: {
      can: {
        title: 'Delete record',
        hasForm: {
          message: 'This 1042-S recipient record has countForm forms linked to it. Deleting this recipient will also delete the linked form records and cannot be undone.'
        },
        hasNoForm: {
          message: 'Do you really want to delete this record? This process cannot be undone.'
        },
        actionLabel: 'Delete'
      },
      cant: {
        title: `This record can't be deleted`,
        message: 'This 1042-S recipient record has forms linked to it that have been printed and/or transmitted and cannot be deleted.'
      }
    }
  }),
  F: getFormConstant({
    name: '1042-S',
    isTaxForm: true,
    delete: formDelete,
    formType: 'F1042S'
  }),
  D: getFormConstant({
    name: '1099-DIV',
    isTaxForm: true,
    delete: formDelete,
    formType: 'Div'
  }),
  I: getFormConstant({
    name: '1099-INT',
    isTaxForm: true,
    delete: formDelete,
    formType: 'Int'
  }),
  M: getFormConstant({
    name: '1099-MISC',
    isTaxForm: true,
    delete: formDelete,
    formType: 'Misc'
  }),
  B: getFormConstant({
    name: '1099-B',
    isTaxForm: true,
    delete: formDelete,
    formType: 'B'
  }),
  X9K: getFormConstant({
    name: '1099-K',
    isTaxForm: true,
    delete: formDelete,
    formType: 'K'
  }),
  X9N: getFormConstant({
    name: '1099-NEC',
    isTaxForm: true,
    delete: formDelete,
    formType: 'Nec'
  }),
  S: getFormConstant({
    name: '1099-S',
    isTaxForm: true,
    delete: formDelete,
    formType: 'S'
  }),
  8: getFormConstant({
    name: '1098',
    isTaxForm: true,
    delete: formDelete,
    formType: '1098'
  }),
  A: getFormConstant({
    name: '1099-A',
    isTaxForm: true,
    delete: formDelete,
    formType: 'A'
  }),
  C: getFormConstant({
    name: '1099-C',
    isTaxForm: true,
    delete: formDelete,
    formType: 'C'
  }),
  321: getFormConstant({
    name: '3921',
    isTaxForm: true,
    delete: formDelete,
    formType: '3921'
  }),
  322: getFormConstant({
    name: '3922',
    isTaxForm: true,
    delete: formDelete,
    formType: '3922'
  }),
  R: getFormConstant({
    name: '1099-R',
    isTaxForm: true,
    delete: formDelete,
    formType: 'R'
  }),
  5: getFormConstant({
    name: '5498',
    isTaxForm: true,
    delete: formDelete,
    formType: '5498'
  }),
  G: getFormConstant({
    name: 'W-2G',
    isTaxForm: true,
    delete: formDelete,
    formType: 'W2G'
  }),
  ...w2Enabled() && {
    6: getFormConstant({
      name: '499R-2',
      isTaxForm: true,
      delete: formDelete,
      formType: '499R2'
    }),
    WVI: getFormConstant({
      name: 'W-2VI',
      isTaxForm: true,
      delete: formDelete,
      formType: 'WVI'
    }),
    W: getFormConstant({
      name: 'W-2',
      isTaxForm: true,
      delete: formDelete,
      formType: 'W2'
    })
  },
  O: getFormConstant({
    name: '1099-OID',
    isTaxForm: true,
    delete: formDelete,
    formType: 'OID'
  }),
  ...w2MEnabled() && {
    W2M: getFormConstant({
      name: 'W-2M',
      isTaxForm: true,
      delete: formDelete,
      formType: 'W2M',
      basePath: 'forms/W-2M'
    })
  }
}
);

export {
  payerFchar,
  recipientFchar,
  recipient1042SFchar,
  noFormsFchars,
  payeesFChars,
  the1042SFchar,
  theW2Fchar,
  theW2VIFchar,
  the499R2Fchar,
  non1099Forms,
  w2Forms,
  theNecFormInfo,
  the499R2FormInfo,
  the499R2NameForFederal,
  the499R2NameForFederalCorrection,
  the945FormTaxEntityCode,
  the1042FormTaxEntityCode,
  withholdingForms,
  allFormsFchar,
  statesWithoutCorrection,
  taxEntityType,
  getRecordName,
  getFormType,
  getOnSearchPath,
  getBasePath,
  getEntityPath,
  getKey,
  alphabetizeForms,
  getAllRecords,
  getAllFCharsTaxForms,
  getAllFCharsSorted,
  getFchar1099Records,
  get1042SRecords,
  get1099Records,
  isTaxForm,
  getPanelTitle,
  getFcharByName,
  getFcharBySingularName,
  getRecords,
  allRecords,
  filterFormsToDisplay,
  getFormsNames,
  formsEligibleAsCorrections,
  theW2MFchar,
  theNECFchar,
  mailingAddressAutoCheckBoxFields
};