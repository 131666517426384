import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from 'mosaic-react/sovos-tabs';
import Tab from 'mosaic-react/sovos-tab';
import { Loader } from 'enhancers';
import IconMenu from 'mosaic-react/sovos-icon-menu/SovosIconMenu';
import MenuItem from 'mosaic-react/sovos-menu-item';
import Add from '@mui/icons-material/AddCircle';
import FormContainer from '../formContainer/FormContainerConnector';
import { getRecords, getRecordName, isTaxForm, alphabetizeForms, payerFchar, the1042SFchar, recipient1042SFchar, recipientFchar } from 'helpers/formConstants';
import ConfirmationDialog from 'shared/confirmationDialog/ConfirmationDialog';
import './formInTabs.scss';

const FormInTabs = ({
  mainTab,
  formId,
  newForm,
  taxEntityCode,
  taxEntityCodeBase,
  formModified,
  fetchingMetadata,
  fetchingEntities,
  fetchingForm,
  saveFormSuccess,
  entities,
  modRecNo,
  allEntities,
  dynamicFormActions,
  entityActions,
  handleAddNewForm
}) => {
  const [currentTab, setCurrentTab] = useState(mainTab);
  const [isNewForm, setIsNewForm] = useState(newForm);
  const [nextTab, setNextTab] = useState('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  useEffect(() => {
    if (modRecNo) {
      entityActions.fetchEntitiesByPayee(modRecNo);
    }
  }, [modRecNo]);

  useEffect(() => {
    if (modRecNo && saveFormSuccess) {
      entityActions.fetchEntitiesByPayee(modRecNo);
      if (currentTab === mainTab && isNewForm) {
        setIsNewForm(false);
      }
    }
  }, [saveFormSuccess]);

  useEffect(() => {
    if (currentTab !== taxEntityCode && allEntities.length > 0) {
      dynamicFormActions.setTaxEntityCode(currentTab);
      dynamicFormActions.setFcharToDelete(currentTab);
      const entity = allEntities.find(entity => Boolean(entity[currentTab] && entity[currentTab][0]));
      if (currentTab === mainTab && (!entity || isNewForm)) {
        dynamicFormActions.setFormId(-1);
      } else {
        dynamicFormActions.setFormId(entity[currentTab][0].id);
      }
    }
  }, [currentTab, allEntities]);

  const handleTabChange = (_, newTab) => {
    if (formModified) {
      setNextTab(newTab);
      setOpenConfirmationDialog(true);
    }
    else {
      setCurrentTab(newTab);
    }
  };

  const handleOnOkConfirmation = () => {
    setOpenConfirmationDialog(false);
    setCurrentTab(nextTab);
    setNextTab('');
  };

  const calculatePosition = () => ((allEntities.length + 1) * 135) + 10;

  const getActiveTabColor = tab => currentTab === tab ? 'form-in-tabs__tab-active' : 'form-in-tabs__tab-inactive';

  const getRelatedTabs = () => entities.map(entity => {
    const tabTaxEntityCode = Object.keys(entity)[0];
    const label = (
      <span>
        { getRecordName(tabTaxEntityCode) }
        { entity[tabTaxEntityCode]?.length > 1 && <span className='form-in-tabs__tab-label'> ({ entity[tabTaxEntityCode].length })</span> }
      </span>
    );

    return (
      <Tab
        label={ label }
        key={ tabTaxEntityCode }
        value={ tabTaxEntityCode }
        className={ `form-in-tabs__tab ${getActiveTabColor(tabTaxEntityCode)}` }
      />
    );
  });

  const renderTabsRecords = () => {
    if (currentTab) {
      return (
        <>
          <Tabs
            value={ currentTab }
            onChange={ handleTabChange }
          >
            {
              <Tab
                label={ getRecordName(taxEntityCodeBase) }
                key={ taxEntityCodeBase }
                value={ taxEntityCodeBase }
                className={ `form-in-tabs__tab ${ getActiveTabColor(taxEntityCodeBase) }` }
              />
            }
            {
              entities && getRelatedTabs()
            }
          </Tabs>
          {
            renderFormContainer()
          }
        </>
      );
    }
  };

  const renderFormContainer = () => (
    <FormContainer
      taxEntityCode={ taxEntityCode }
      formId={ formId }
      applyStyle={ Boolean(allEntities.length) }
    />
  );

  const renderRecords = () => newForm && [payerFchar, recipientFchar, recipient1042SFchar].includes(currentTab)
    ? renderFormContainer()
    : renderTabsRecords();

  return (
    <div className='form-in-tabs__container'>
      {
        (!fetchingMetadata && !fetchingEntities && !fetchingForm && !newForm && taxEntityCode !== payerFchar)
          ? <div
            className='form-in-tabs__icons'
            style={ { left: calculatePosition() } }
          >
            <IconMenu
              key='RecordMenu'
              iconElement={ <Add data-for='addRecord' className='form-in-tabs__icon' /> }
            >
              {
                [the1042SFchar, recipient1042SFchar].includes(taxEntityCode)
                  ? <MenuItem
                    value={ the1042SFchar }
                    onClick={ () => handleAddNewForm(the1042SFchar) }
                    className='form-in-tab__menu-item'>
                    { getRecordName(the1042SFchar) }
                  </MenuItem>
                  : Object.keys(getRecords())
                    .sort(alphabetizeForms)
                    .map(option => {
                      if (!isTaxForm(option) || option === the1042SFchar) {
                        return null;
                      }
                      return (
                        <MenuItem
                          key={ option }
                          value={ option }
                          className='form-in-tab__menu-item'
                          onClick={ () => handleAddNewForm(option) }
                        >
                          { getRecordName(option) }
                        </MenuItem>
                      );
                    })
              }
            </IconMenu>
          </div>
          : ''
      }
      <Loader
        isLoading={ fetchingMetadata || fetchingEntities || fetchingForm }
      >
        {
          renderRecords()
        }
      </Loader>
      <ConfirmationDialog
        open={ openConfirmationDialog }
        onOKClick={ handleOnOkConfirmation }
        onCancelClick={ () => setOpenConfirmationDialog(false) }
        title='Discard changes'
        content='Are you sure you want to discard your changes?'
        actionLabel='Discard'
      />
    </div>
  );
};

FormInTabs.propTypes = {
  mainTab: PropTypes.string.isRequired,
  formId: PropTypes.number,
  newForm: PropTypes.bool,
  taxEntityCode: PropTypes.string.isRequired,
  taxEntityCodeBase: PropTypes.string.isRequired,
  formModified: PropTypes.bool,
  fetchingMetadata: PropTypes.bool.isRequired,
  fetchingEntities: PropTypes.bool.isRequired,
  fetchingForm: PropTypes.bool.isRequired,
  saveFormSuccess: PropTypes.bool.isRequired,
  entities: PropTypes.array,
  modRecNo: PropTypes.number,
  allEntities: PropTypes.array,
  dynamicFormActions: PropTypes.shape({
    setFcharToDelete: PropTypes.func,
    setFormId: PropTypes.func,
    setTaxEntityCode: PropTypes.func
  }),
  entityActions: PropTypes.shape({
    fetchEntitiesByPayee: PropTypes.func
  }).isRequired,
  handleAddNewForm: PropTypes.func
};

FormInTabs.defaultProps = {
  modRecNo: -1,
  formId: -1,
  entities: [],
  allEntities: [],
  handleAddNewForm: () => null
};

export default FormInTabs;
