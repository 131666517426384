import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SovosChip from 'mosaic-react/sovos-chip-choice';
import { getRecordName, noFormsFchars, recipientFchar, payerFchar, theW2MFchar } from 'helpers/formConstants';
import { getTransmittedTo } from 'helpers/trackerHelper';
import { getFormName } from 'shared/dataEntryForms/sovos-dynamic-form/DynamicFormHelper';

const getW2MXmitChip = (items, singularLabel, pluralLabel) => {
  const transmittedStatesOrLocalities = items.reduce((acc, cur) => acc + (cur.Xmitrun ? 1 : 0), 0);

  if (!transmittedStatesOrLocalities) {
    return;
  }

  let label;
  if (items.length === transmittedStatesOrLocalities) {
    if (items.length === 1) {
      label = `Transmitted to ${singularLabel}`;
    } else {
      label = `Transmitted to all ${pluralLabel}`;
    }
  } else if (items.length > transmittedStatesOrLocalities) {
    if (transmittedStatesOrLocalities === 1) {
      label = `Transmitted to a ${singularLabel}`;
    } else {
      label = `Transmitted to some ${pluralLabel}`;
    }
  } else {
    return;
  }

  return <SovosChip
    variant='choice'
    className='form-editor__header_pt-label'
    label={ label }
  />;
};

const FormTitle = ({
  currentTab,
  newForm,
  recipientInformation,
  formEditorTitle,
  formPrinted,
  formTransmittedToIrs,
  formTransmittedToState,
  formTransmittedToLocality,
  isCustomCorrection,
  states,
  localities
}) => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    const isForm = () => Boolean(currentTab && recipientInformation.Name)
      && ![...noFormsFchars, ''].includes(currentTab);

    const getFormTitle = () =>
      `${recipientInformation.Name} ${recipientInformation.Name2 || ''} / ${getFormName(getRecordName(currentTab), isCustomCorrection)}`;

    let _title = '';
    if (newForm) {
      if (isForm()) {
        _title = getFormTitle();
      } else {
        _title = `Add ${currentTab
          ? [payerFchar, recipientFchar].includes(currentTab)
            ? getRecordName(currentTab).toLowerCase()
            : getRecordName(currentTab)
          : 'record'}`;
      }
    } else if (isForm()) {
      _title = getFormTitle();
    } else {
      _title = formEditorTitle;
    }

    setTitle(_title);
  }, [recipientInformation, currentTab, isCustomCorrection, newForm]);

  return (
    <div className="form-editor__header_title" data-for='formHeader'>
      <div className="header_title">
        { title }
      </div>
      {
        formPrinted &&
        <SovosChip
          variant='choice'
          className='form-editor__header_pt-label'
          label='Printed'
        />
      }
      {
        formTransmittedToIrs &&
        <SovosChip
          variant='choice'
          className='form-editor__header_pt-label'
          label={ getTransmittedTo(currentTab) }
        />
      }
      {
        currentTab === theW2MFchar
          ? getW2MXmitChip(states, 'state', 'states')
          : formTransmittedToState &&
            <SovosChip
              variant='choice'
              className='form-editor__header_pt-label'
              label='Transmitted to state'
            />
      }
      {
        currentTab === theW2MFchar
          ? getW2MXmitChip(localities, 'locality', 'localities')
          : formTransmittedToLocality &&
            <SovosChip
              variant='choice'
              className='form-editor__header_pt-label'
              label='Transmitted to locality'
            />
      }
    </div>
  );
};

FormTitle.defaultProps = {
  states: []
};

FormTitle.propTypes = {
  currentTab: PropTypes.string.isRequired,
  newForm: PropTypes.bool.isRequired,
  recipientInformation: PropTypes.object.isRequired,
  formEditorTitle: PropTypes.string.isRequired,
  formPrinted: PropTypes.bool.isRequired,
  formTransmittedToIrs: PropTypes.bool.isRequired,
  formTransmittedToState: PropTypes.bool.isRequired,
  formTransmittedToLocality: PropTypes.bool.isRequired,
  isCustomCorrection: PropTypes.bool.isRequired,
  states: PropTypes.array,
  localities: PropTypes.array
};

export default FormTitle;